@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300&family=Roboto:wght@500&display=swap&subset=latin,latin-ext");
@import "./data/styles.css";

body {
	margin: 0;
	font-family: var(--primary-font);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Roboto,
		monospace;
}
